///
/// Dimension by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Wrapper */

	#wrapper {
		@include vendor('display', 'flex');
		@include vendor('flex-direction', 'column');
		@include vendor('align-items', 'center');
		@include vendor('justify-content', 'space-between');
		position: relative;
		min-height: 100vh;
		width: 100%;
		padding: 2rem 2rem;
		z-index: 3;

		&:before {
			content: '';
			display: block;
		}

		.alttitle {
			position: absolute;
			left: 5%;
		}

		@include breakpoint(xlarge) {
			padding: 3rem 2rem;
		}

		@include breakpoint(medium) {
			padding: 1rem;

			.alttitle {
				position: absolute;
				left: 5%;

				h1 {
					font-size: 1.5rem;
					letter-spacing: 0.25rem;
				}
			}
		}

		@include breakpoint(small) {
			padding: 2rem 1rem;

			.alttitle {
				position: absolute;
				left: 5%;

				h1 {
					font-size: .8rem;
					letter-spacing: 0.25rem;
				}
			}
		}

		

		// &.page {
			@include vendor('justify-content', 'flex-start');
		// }
	}