///
/// Dimension by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* BG */

	#bg {
		@include vendor('transform', 'scale(1.0)');
		-webkit-backface-visibility: hidden;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		z-index: 1;

		&:before, &:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		&:before {
			// @include vendor('transition', 'background-color #{_duration(bg)} ease-in-out');
			// @include vendor('transition-delay', '#{_duration(intro)}');
			background-image:		linear-gradient(to top, #{_palette(bg-overlay)}, #{_palette(bg-overlay)}),
									url('../../images/overlay.png');
			background-size:		auto,
									256px 256px;
			background-position:	center,
									center;
			background-repeat:		no-repeat,
									repeat;
			z-index: 2;
		}

		&:after {
			@include vendor('transform', 'scale(1.125)');
			// @include vendor('transition', (
			// 	'transform #{_duration(article)} ease-in-out',
			// 	'filter #{_duration(article)} ease-in-out'
			// ));
			background-image:		url('../../images/bgb.png');
			background-position:	center;
			background-size:		cover;
			background-repeat:		no-repeat;
			z-index: 1;
		}

		.body.is-article-visible & {
			&:after {
				@include vendor('transform', 'scale(1.0825)');
				@include vendor('filter', 'blur(0.2rem)');
			}
		}

		.body.is-loading & {
			&:before {
				background-color:	_palette(bg-alt);
			}
		}
	}